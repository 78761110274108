/* Base Styles */
.nav-header {
  padding: 10px 60px;
  background-color: white; /* Set background to white */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative;
  transition: all 0.3s ease;
  z-index: 1000;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-bar-large-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-logo {
  display: flex;
  align-items: center;
}

.website-logo {
  max-width: 120px; /* Limit logo size */
  height: auto;
}

.nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 40px; /* Increased space between items */
}

.nav-menu-item {
  position: relative; /* For dropdown indicators */
}

.nav-link {
  text-transform: uppercase;
  padding: 12px 16px;
  color: #333; /* Darker text color */
  text-decoration: none;
  border-radius: 4px; /* Rounded corners */
  transition: background 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.nav-link:hover {
  /* Light background on hover */
  color: #007b9c; /* Change text color on hover */
}

.nav-link.active {
  color: #007b9c;
  font-weight: bold;
}

.nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #007b9c;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.nav-link:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #007b9c;
  position: absolute;
  left: 0;
  bottom: -5px;
}

.logout-desktop-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007b9c;
}

.nav-hamburger-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  color: #007bff;
  font-size: 24px;
}

.nav-menu-mobile {
  display: none;
  position: absolute;
  top: 70px;
  right: 30px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.nav-menu-mobile.open {
  display: block;
  opacity: 1;
  visibility: visible;
}

.nav-menu-list-mobile {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.nav-menu-item-mobile {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.nav-menu-item-mobile:last-child {
  border-bottom: none;
}

.nav-menu-item-mobile .nav-link {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
}

.nav-bar-image {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.logout-icon {
  font-size: 30px;
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
  }

  .nav-hamburger-btn {
    display: block;
  }

  .nav-menu-mobile {
    display: none;
  }

  .nav-menu-mobile.open {
    display: block;
  }
}
@media (max-width: 768px) {
  .logout-desktop-btn {
    display: none;
  }
}

/* For devices larger than 768px (desktop devices) */
@media (min-width: 769px) {
  .logout-desktop-btn {
    display: block;
  }
}
.logout-mobile-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #007b9c;
}
.logout-alignment-mb {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: -10px;
  margin-left: -40px;
}
