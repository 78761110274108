* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.hero {
  position: relative;
  height: 60vh;
  background: linear-gradient(135deg, #007d9d, #14a4c9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 50px;
  border-radius: 10px;
}

.status-label {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  color: white;
}

.status-label.active {
  background-color: lightgreen;
}

.status-label.inactive {
  background-color: lightcoral;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2rem;
  margin-top: 10px;
  opacity: 0.9;
}

.categories-section {
  padding: 40px 20px;
  background-color: #f0f2f5;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
  color: #007d9d;
}

.quiz-categories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .quiz-categories {
    grid-template-columns: 1fr;
  }
}

.category-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.category-content {
  padding: 20px;
  text-align: center;
}

.category-content h3 {
  font-size: 1.8rem;
  color: #007d9d;
  margin-bottom: 10px;
}

.category-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.category-content button {
  padding: 10px 20px;
  background: linear-gradient(135deg, #007d9d, #14a4c9);
  border: none;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.category-content button:hover {
  background: linear-gradient(135deg, #14a4c9, #007d9d);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 15px;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 15px;
}

.modal-content button {
  margin: 5px;
  padding: 10px 15px;
  background: linear-gradient(135deg, #007d9d, #14a4c9);
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background: linear-gradient(135deg, #14a4c9, #007d9d);
}
.analytics-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: linear-gradient(135deg, #007d9d 30%, #00a9d4 100%);
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin: 50px auto;
  max-width: 1200px;
}

.analytics-box {
  flex: 1;
  min-width: 220px;
  background-color: #fff;
  margin: 15px;
  padding: 40px 20px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 125, 157, 0.2);
  position: relative;
  overflow: hidden;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.analytics-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 35px rgba(0, 125, 157, 0.3);
}

.analytics-box::before {
  content: "";
  position: absolute;
  top: -50px;
  right: -50px;
  width: 150px;
  height: 150px;
  background: rgba(0, 125, 157, 0.15);
  border-radius: 50%;
}

.analytics-box .number {
  font-size: 3em;
  font-weight: bold;
  color: #007d9d;
  transition: color 0.3s ease;
}

.analytics-box:hover .number {
  color: #00a9d4;
}

.analytics-box .label {
  font-size: 1.4em;
  margin-top: 10px;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .analytics-container {
    flex-direction: column;
    align-items: center;
  }

  .analytics-box {
    min-width: 80%;
  }
}

@media (max-width: 768px) {
  .analytics-box .number {
    font-size: 2.5em;
  }

  .analytics-box .label {
    font-size: 1.2em;
  }
}
