/* index.css */
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.carousel-container {
  width: 100%;
  height: 100%;
}

.carousel-item-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.carousel-item-content .text-box {
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
}

.quiz-options {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.quiz-options li {
  margin-bottom: 10px;
}

.feedback-form {
  width: 100%;
}

.feedback-form textarea {
  width: 100%;
  margin-bottom: 20px;
}

.feedback-form input[type="number"] {
  width: 100%;
  margin-bottom: 20px;
}

.feedback-form button {
  width: 100%;
}
