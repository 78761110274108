* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-main-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ffff 50%, #007d9d 50%);
  font-family: "Arial", sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  text-align: center;
}

.login-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: none;
  background-color: #f5f5f5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 30px;
  background-color: #007d9d;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #477f8d;
}

.toggle-text {
  margin-top: 20px;
  color: #333;
}

.toggle-link {
  color: #7a7dfd;
  text-decoration: none;
  font-weight: bold;
}

.toggle-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .login-box {
    padding: 20px;
  }
}
.login-page-image {
  margin-bottom: 10px;
  height: 150px;
  width: 150px;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.retry-btn {
  background-color: #007d9d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.retry-btn:hover {
  background-color: #477f8d;
}
.wrong-bg {
  background-color: rgb(236, 191, 191);
  border-style: solid;
  border-color: rgb(232, 122, 122);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
