.notfound-404-image {
  height: 550px;
  width: 550px;
}
.notfound-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.notfound-button {
  padding: 10px 30px;
  background: linear-gradient(135deg, #007d9d, #14a4c9);
  border: none;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
}
