/* General styles */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.start-quiz-main-container {
  margin: 10px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  background-color: #f0f4f8;
}

.animated-bg {
  background: url("https://example.com/quiz-bg.jpg") no-repeat center center
    fixed;
  background-size: cover;
}

.quiz-container {
  margin: auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  max-width: 1000px;
  width: 100%;
  margin-top: 10px;
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.quiz-header h2 {
  font-size: 2rem;
  color: #333;
}

.timer-container {
  display: flex;
  align-items: center;
}

.timer {
  font-size: 1.5rem;
  color: #007b9c;
  margin-right: 1rem;
}

.quiz-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.question-section {
  flex: 1;
  padding-right: 1rem;
}

.question {
  margin-bottom: 1.5rem;
}

.question h3 {
  font-size: 1.75rem;
  color: #333;
}

.options {
  display: flex;
  flex-direction: column;
}

.option-btn {
  background-color: #fff;
  border: 2px solid #ddd;
  padding: 10px;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

.option-btn:hover {
  border-color: #007b9c;
}

.option-btn.selected {
  background-color: #007b9c;
  color: #fff;
  border-color: #007b9c;
}

.navigation-buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  padding: 10px 20px;
  font-size: 1.25rem;
  background-color: #007b9c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.nav-btn:hover {
  background-color: #007b9c;
}

.side-panel {
  width: 250px;
  border-left: 2px solid #ddd;
  padding-left: 1rem;
  margin-top: 2rem;
}

.question-status p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.question-number-btn {
  background-color: #f0f4f8;
  border: 2px solid #ddd;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.25rem;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background-color 0.3s;
}

.question-number-btn.active,
.question-number-btn:hover {
  background-color: #007b9c;
  color: #fff;
  border-color: #007b9c;
}

/* Completion Section */
.quiz-completion {
  text-align: center;
}

.quiz-completion h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.restart-btn {
  padding: 10px 20px;
  font-size: 1.25rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.restart-btn:hover {
  background-color: #218838;
}

/* Modal */
.exit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 400px;
}

.modal-content p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.confirm-btn,
.cancel-btn {
  padding: 10px 20px;
  font-size: 1.25rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.confirm-btn {
  background-color: #0fa0c8;
  color: #fff;
  transition: background-color 0.3s;
}

.confirm-btn:hover {
  background-color: #0fa0c8;
}

.cancel-btn {
  background-color: #ddd;
  color: #333;
  transition: background-color 0.3s;
}

.cancel-btn:hover {
  background-color: #ccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .quiz-container {
    padding: 1rem;
  }

  .quiz-header h2 {
    font-size: 1.5rem;
  }

  .timer {
    font-size: 1.2rem;
  }

  .progress-circle {
    width: 50px;
    height: 50px;
  }

  .question h3 {
    font-size: 1.5rem;
  }

  .option-btn {
    font-size: 1rem;
    padding: 8px;
  }

  .nav-btn {
    padding: 8px 16px;
    font-size: 1rem;
  }

  .side-panel {
    width: 100%;
    padding-left: 0;
    border-left: none;
    margin-top: 1rem;
  }

  .question-number-btn {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .quiz-content {
    flex-direction: column;
  }

  .quiz-completion h3 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .quiz-header h2 {
    font-size: 1.25rem;
  }

  .timer {
    font-size: 1rem;
  }

  .progress-circle {
    width: 40px;
    height: 40px;
  }

  .question h3 {
    font-size: 1.25rem;
  }

  .option-btn {
    font-size: 0.9rem;
    padding: 6px;
  }

  .nav-btn {
    padding: 6px 12px;
    font-size: 0.9rem;
  }

  .question-number-btn {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .quiz-completion h3 {
    font-size: 1.75rem;
  }

  .restart-btn {
    padding: 8px 16px;
    font-size: 1rem;
  }
}
.quiz-image {
  width: 200px;
  height: 200px;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress {
  height: 10px;
  background-color: #007b9c;
  width: 0;
  transition: width 0.3s ease-in-out;
}
.finishh-btn {
  background-color: rgb(221, 38, 38);
}
.question-and-skip-alignment {
  display: flex;
  justify-content: space-between;
}
.skip-button {
  background-color: transparent;
  border-style: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}
.question-number-btn.answered {
  background-color: green;
  color: white;
}

.quiz-image {
  width: 120px;  /* or any width you prefer */
  height: auto;  /* maintain aspect ratio */
  max-width: 100%;  /* make images responsive */
}
